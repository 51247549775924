import React, { useState, useRef, useEffect } from "react";
import "./PaymentPage.scss";
import MainLayout from "../../layouts/MainLayout";
import { Form, useNavigate } from "react-router-dom";
import { ROUTES } from "../../lib/consts";
import API from "../../api";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { GA_EVENTS, gtagTrackEvent } from "../../lib/utils";


interface PaymentPageProps {
  onSubmit: (values: any) => void;
}


const PaymentPage: React.FC<PaymentPageProps> = ({ onSubmit }) => {
  const MobileNumberValidation = Yup.object().shape({
    mobileNumber: Yup.string()
      .required("*Please enter UPI linked mobile number")
      .matches(/^\d{10}$/, "*Please enter UPI linked mobile number"),
  });
  const navigate = useNavigate();


  return (
    <MainLayout className="payment-page">
      <div className="payment-container">
        <p className="form-title">ENTER UPI LINKED<br /> MOBILE NUMBER</p>

        <Formik
          initialValues={{
            mobileNumber: "",
          }}
          validationSchema={MobileNumberValidation}
          onSubmit={async (values, { setErrors }) => {
            // navigate(ROUTES.DETAILS);
            try {
              await API.saveUPIDetails(values.mobileNumber);
              onSubmit(values);  // Execute additional actions if needed
              gtagTrackEvent(GA_EVENTS.UPI_click);
              navigate(ROUTES.DETAILS);
            } catch (err: any) {
              console.error("Error submitting UPI ID", err);
              setErrors({ mobileNumber: err.message });
            }
          }}
        >
          {({ handleSubmit }) => (
            <form className="mobile-number-form" onSubmit={handleSubmit}>
              {/* <img className="title-img"  src={paymenttitle} alt="title"/> */}

              <div className="input-group" >
                <label htmlFor="mobileNumber"></label>
                <Field
                  type="text"
                  id="mobileNumber"
                  name="mobileNumber"
                  placeholder="Enter UPI linked Mobile Number*"
                  maxLength={10}
                />
                {/* <p className="upi-text">*Please enter UPI linked mobile number.</p> */}

                <ErrorMessage
                  name="mobileNumber"
                  component="p"
                  className="error"
                />
              </div>

              <div className="payment-btn-container">
                <button className="btn btn-primary" type="submit" >
                  Submit
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
      </MainLayout>
  );
};

export default PaymentPage;
