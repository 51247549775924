import { Route, Routes, useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { ROUTES } from "./lib/consts";
// import Home from "./pages/Home/Home";
import Register from "./pages/Register/Register";
import { useEffect, useState } from "react";
import API from "./api";
import { useGlobalLoaderContext } from "./helpers/GlobalLoader";
import store from "./store/store";
import { setUserIdentification } from "./store/actions/authAction";
import { logoutUser } from "./lib/utils";
import { useAuthentication } from "./hooks/useAuthentication";
import PrivateRoute from "./helpers/PrivateRoute";

import Thankyou from "./pages/Design/Thankyou/Thankyou";
import { Participated } from "./pages/Participated/Participated";
import { useNavigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import { Reward } from "./pages/Reward/Reward";
import PaymentPage from "./pages/Payment/PaymentPage";
import Details from "./pages/Details/Details";

function App() {
  const { showLoader, hideLoader } = useGlobalLoaderContext();
  const { isLoggedIn } = useAuthentication();
  const { search } = useLocation();
  const [isParam, setIsParasm] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    API.initialize(showLoader, hideLoader);
    API.createUser().then((response: any) => {
      store.dispatch(setUserIdentification(response));
      if (!response.isLoggedIn && isLoggedIn) {
        logoutUser();
        toast.info("Your session has been expired");
      }
      // addCaptchaScript();
    });
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      logoutUser();
      navigate(ROUTES.REGISTER);
      toast.info("Your session has been expired");
    };
  }, []);

  return (
    <div className="App">
      {/* {isParam? ( */}
      <Routes>
        {/*<Route path={ROUTES.HOME} element={<Home />} />*/}
        <Route path={ROUTES.REGISTER} element={<Register />} />

        <Route
          path={ROUTES.PAYMENT_PAGE}
          element={
            <PrivateRoute>
            <PaymentPage onSubmit={(values) => console.log(values)} />
             </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.DETAILS}
          element={
            <PrivateRoute>
            <Details onSubmit={(values) => console.log(values)} />
             </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.REWARD}
          element={
            <PrivateRoute>
            <Reward />
             </PrivateRoute>
          }
        />


        <Route
          path={ROUTES.PARTICPATED}
          element={
            <PrivateRoute>
              <Participated />
            </PrivateRoute>
          }
        />


        {/*<Route path={ROUTES.COMPLETED} element={<Completed />} />*/}
      </Routes>
      {/* ):(<NotAllowed/>)} */}

      {/*<ToastContainer position="bottom-center" hideProgressBar={true} />*/}
    </div>
  );
}

export default App;
