import { PropsWithChildren, useState } from "react";
import { useLocation } from "react-router-dom";
import headerlogo from "../assets/images/header-logo.png";
import dogImage from "../assets/images/dogimage.png";

// import headersublogo from "../assets/images/Piyo.png";
import desktoplogobg from "../assets/images/desktop-logo-bg.png";
import desktoplogo from "../assets/images/Logo.png";

import Logo from "../assets/images/Logo.png";
import MenuIcon from "../assets/images/menu-icon.svg";
import footer from "../assets/images/bottom.png";
import desktopfooter from "../assets/images/desktop-bottom.png";

import "./MainLayout.scss";
import SideMenu from "../components/SideMenu/SideMenu";
import { MODAL_TYPES, useGlobalModalContext } from "../helpers/GlobalModal";
import { useTranslation } from "react-i18next";
import { LANGUAGE, LANGUAGES, ROUTES } from "../lib/consts";
import { useNavigate } from "react-router-dom";
import paymentFooter from "../assets/images/malkist.png";

const MainLayout = ({
  children,
  className,
}: { className: string } & PropsWithChildren) => {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const { showModal } = useGlobalModalContext();
  const navigate = useNavigate();
  // const { t } = useTranslation();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const isCongratsPage = location.pathname === ROUTES.CONGRATULATION;
  // console.log(i18n);
  return (
    <div className={"main-layout " + className}>
      <SideMenu open={showSideMenu} onClose={() => setShowSideMenu(false)} />
      <header>
        

        <img
          src={MenuIcon}
          onClick={() => setShowSideMenu(true)}
          alt="menu"
          className="menu-icon"
        />
        {
          <img
            src={headerlogo}
            alt="logo"
            className="logo"
            // onClick={() => navigate(ROUTES.REGISTER)}
          />
        }

        {/* <img src={headersublogo} alt="sub-log" className="sub-logo" /> */}
      </header>
      <div className="content">
        <div className="border-container">
        <img className="dog-image" src={dogImage} alt="Logo"/>
        {children} 
        </div>
        </div>

      {/* <img
        src={isCongratsPage ? "" : footer} // Conditionally render the footer image
        className="mobile-footer"
        alt=""
      /> */}
    </div>
  );
};

export default MainLayout;
