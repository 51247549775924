import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const ContactUsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="contact-us-popup" title="Contact us">
      <p className="text3" style={{ minWidth: "300px" }}>
      <p className="text3">
        <p>
          <strong>Customer Support number:</strong> <br/>080 69806393
          <p>(Mon-Fri 9am to 6pm IST)</p>
        </p>
      </p>
        <a className="text3" href={"starterkit@pinelabs.com"}>
          <strong>Customer Support Email:</strong> starterkit@pinelabs.com
        </a>
      </p>
      
    </Popup>
  );
};

export default ContactUsPopup;
