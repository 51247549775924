import MainLayout from "../../layouts/MainLayout";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import "./Register.scss";
import { useEffect, useRef, useState } from "react";
import footer from "../../assets/images/bottom.png";
import API from "../../api";
import { ERROR_IDS } from "../../api/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { LANGUAGE, LANGUAGES, OUTLET, ROUTES, STATES } from "../../lib/consts";
import store from "../../store/store";
import { canClaim, setAccessToken, limit, amt } from "../../store/actions/authAction";
import { useTranslation } from "react-i18next";
import { MODAL_TYPES, useGlobalModalContext } from "../../helpers/GlobalModal";
// import CashbackPayout from "../CashbackPayout/CashbackPayout";
import { useAuthentication } from "../../hooks/useAuthentication";
import { hideCaptchaBadge, showCaptchaBadge, renderCaptcha } from "../../lib/utils";
import { setUserIdentification } from "../../store/actions/authAction";
import { log } from "console";
import arrow from "../../assets/images/arrow.png";
import register from "../../assets/images/Register.png";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";
import { useSearchParams } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// declare const turnstile: any;


const Register = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const { showModal } = useGlobalModalContext();
  const [resendTimer, setResendTimer] = useState(60);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showPayoutForm, setShowPayoutForm] = useState(false);
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const [searchParams] = useSearchParams();
  const referralCodeFromUrl = searchParams.get("referral");
  const referralSet = useRef(false);
  const [tokenValue, setTokenValue] = useState("");
  // const [cloudFareToken, setCloudFareToken] = useState("");
  // const widgetId = useRef<any>();
  const { isLoggedIn } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();

  const timer = useRef<any | null>(null);

  const { t } = useTranslation();
  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
    setCheckboxError("");
  };

  useEffect(() => {
    if (isLoggedIn) {
      const handleLocationChange = () => {
        // Perform your back navigation logic here
        navigate(ROUTES.PAYMENT_PAGE); // Change this to the appropriate route
      };

      window.addEventListener("popstate", handleLocationChange);

      return () => {
        window.removeEventListener("popstate", handleLocationChange);
      };
    }
  }, [navigate, isLoggedIn]);

  // useEffect(() => {
  //   if (typeof turnstile !== "undefined") {
  //     turnstile.ready(() => {
  //       if (!widgetId.current) {
  //         widgetId.current = turnstile.render("#cf-turnstile-otp", {
  //           sitekey: process.env.REACT_APP_CAPTCHA_SITE_KEY,
  //           theme: "light",
  //           callback: (tokenValue: string) => {
  //             setCloudFareToken(tokenValue)
  //             console.log("token", tokenValue)
  //           },
  //         });
  //       }
  //     });
  //   } else {
  //     console.error("Turnstile script not loaded.");
  //   }
  // }, []);

  const RegisterValidation = Yup.object().shape({
    mobile: Yup.string()
      .required("*Please enter valid 10-digit number")
      .matches(
        /^[56789][0-9]{9}$/,

        "*Please enter valid 10-digit number"
      ),
  });

  const OtpValidation = Yup.object().shape({
    otp: Yup.string()
      .required(
        t("registerPage.errors.invalidOtp") || "*Please enter valid OTP"
      )
      .matches(
        /^[0-9]{6}$/,
        t("registerPage.errors.incorrectOtp") ||
        "*You have entered an incorrect OTP. Please try again"
      ),
  });

  // const navigate = useNavigate();


  const startTimer = () => {
    if (timer) clearInterval(timer.current);
    let time = 60;
    setResendTimer(time);
    timer.current = setInterval(() => {
      time--;
      setResendTimer(time);
      if (time <= 0) {
        clearInterval(timer.current);
      }
    }, 1000);
  };

  const resendOtp = () => {
    if (resendTimer <= 0) {
      startTimer();
      API.resendOTP().catch((err) => {
        console.log(err);
      });
    }
  };

  function renderRegisterForm() {
    return (
      <Formik
        key="register-form"
        initialValues={{
          mobile: "",
          // tokenValue
        }}
        validationSchema={RegisterValidation}
        onSubmit={(values, errors) => {
          console.log("values", values);
          // setShowStateDropdown(false);
          // setShowOtpForm(true);
          // if (!tokenValue) {
          //   errors.setErrors({
          //     agree: "*Please complete captcha challenge",
          //   });
          //   return;
          // }
          
            console.log(values);
            startTimer();
            // values.tokenValue = tokenValue
            API.register(values)
              .then(() => {
                gtagTrackEvent(GA_EVENTS.Registered);
                setShowOtpForm(true);
                startTimer();
              })
              .catch((err) => {
                const { messageId } = err;
                switch (messageId) {
                  case ERROR_IDS.INVALID_MOBILE:
                    errors.setErrors({
                      mobile: "Invalid Mobile Number",
                    });
                    break;

                  default:
                    errors.setErrors({
                      mobile: err.message,
                    });
                    break;
                }
              });
          
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="register-form">
                <p className="form-title ">LOGIN</p>

                <div className="input-group">
                  <label className="input-label"></label>
                  <input
                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    value={values.mobile}
                    name="mobile"
                    maxLength={10}
                    onBlur={handleBlur}
                    placeholder="Mobile Number"
                  />
                </div>
                <div className="input-group">
                  <input
                  style={{background:"#9696968f"}}
                    autoComplete="off"
                    onChange={handleChange}
                    // value={values.mobile}
                    name="otp"
                    maxLength={6}
                    onBlur={handleBlur}
                    disabled
                    placeholder="Enter One Time passcode"
                  />
                </div>
                <div className="flex-center timer-wrapper">
                  {resendTimer > 0 && (
                    <div className="timer flex-center">{resendTimer}</div>
                  )}
                  <span>{t("registerPage.didNotReceive")}</span>
                  <span className="link" onClick={resendOtp}>
                    {t("registerPage.resend")}
                  </span>
                </div>

                {
                  errors.mobile &&
                  touched.mobile && <p className="error">{errors.mobile}</p>
                }

                <button className="btn btn-primary" type="submit">
                  GET ONE TIME PASSCODE
                </button>
              </div>

            </Form>
          );
        }}
      </Formik>
    );
  }

  function renderOtpForm() {
    return (
      <Formik
        key="otp-form"
        initialValues={{
          otp: "",
        }}
        validationSchema={OtpValidation}
        onSubmit={(values, errors) => {
          // navigate(ROUTES.PAYMENT_PAGE);
          API.verifyOTP(values.otp)
            .then((response) => {
              // hideCaptchaBadge();
              // console.log("Access Token:", response.accessToken);
              store.dispatch(setAccessToken(response.accessToken));
              store.dispatch(limit(response.upiAdded));
              // store.dispatch(limit(response.amt));
              // localStorage.setItem("amt", response.amt.toString());
              store.dispatch(amt(response.amt))
              gtagTrackEvent(GA_EVENTS.Verify_OTP);
              const questionData = response.data;

              if (response.upiAdded) {
                navigate(ROUTES.DETAILS);
              } else {
                navigate(ROUTES.PAYMENT_PAGE);
              }
            })
            .catch((err) => {
              const { messageId } = err;
              switch (messageId) {
                case ERROR_IDS.INVALID_OTP:
                  errors.setErrors({
                    otp: "Please enter a valid OTP",
                  });
                  break;
                default:
                  errors.setErrors({
                    otp: err.message,
                  });
                  break;
              }
            });
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="otp-form" >
                  <p className="form-title">LOGIN</p>
                  <div className="input-group">
                  <label className="input-label"></label>
                  <input
                  style={{background:"#9696968f"}}

                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    // value={}
                    disabled
                    name="mobile"
                    maxLength={10}
                    onBlur={handleBlur}
                    placeholder="Mobile Number"
                  />
                </div>
                <div className="input-group">
                  {/* <p className="otp-sub-title">OTP has been sent to your registered<br />mobile number</p> */}
                  <input
                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    value={values.otp}
                    name="otp"
                    maxLength={6}
                    onBlur={handleBlur}
                    placeholder="Enter One Time passcode"
                  />
                </div>
                {errors.otp && touched.otp && (
                  <p className="error">{errors.otp}</p>
                )}
                <div className="flex-center timer-wrapper">
                  {resendTimer > 0 && (
                    <div className="timer flex-center">{resendTimer}</div>
                  )}
                  <span>{t("registerPage.didNotReceive")}</span>
                  <span className="link" onClick={resendOtp}>
                    {t("registerPage.resend")}
                  </span>
                </div>
                <button className="btn btn-primary" type="submit">
                  REGISTER
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  return (
    <MainLayout className="register-page">
      {showOtpForm ? renderOtpForm() : renderRegisterForm()}
    </MainLayout>
  );
};

export default Register;
