import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const TermsPopup = () => {
    const { t } = useTranslation();

    return (
        <Popup className="terms-popup" title="Terms & Conditions">
            <p dir="ltr">
                Terms and Conditions for NESTLE Purina Pro Plan
            </p>
            <ol>
                <li dir="ltr">
                    <p dir="ltr">
                        NESTLE Purina Pro Plan (hereinafter “Program”) is conducted by
                        Nestlé India Limited (hereinafter referred to as ‘Nestlé India’)
                        having its registered at 100/101, World Trade Centre, Barakhamba
                        Lane, New Delhi – 110001 and head office at Nestlé House, Jacaranda
                        Marg, ‘M’ Block, DLF City, Phase – II, Gurgaon- 122002, Haryana,
                        India.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Before participating in the Program, please read the terms and
                        conditions given herein. By participating in the Program, each
                        Participant is deemed to have accepted the complete terms and
                        conditions of this Program.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Program Period: The Program shall start from 10-12.2024 and conclude
                        on 9-04-2025 (The period inclusive of both dates shall be referred
                        to as ‘Term’)
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        This program is valid in select cities in India.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Eligibility Criteria:
                    </p>
                </li>
                <ol>
                    <li dir="ltr">
                        <p dir="ltr">
                            Participants must be individuals aged 18 years or older.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            Only select retailers who sell PURINA ProPlan products will be
                            eligible to participate. Nestlé India reserves the right to
                            limit participation to specific retailers at its discretion.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            This program is applicable exclusively to residents and citizens
                            of India.
                        </p>
                    </li>
                </ol>
                <li dir="ltr">
                    <p dir="ltr">
                        Participation Guidelines and Reward:
                    </p>
                </li>
                <ol>
                    <li dir="ltr">
                        <p dir="ltr">
                            Sampling Kit
                        </p>
                    </li>
                    <ol>
                        <li dir="ltr">
                            <p dir="ltr">
                                Retailers will invite consumers to register for the sampling
                                activity.
                            </p>
                        </li>
                        <li dir="ltr">
                            <p dir="ltr">
                                Sampling Kits will only be provided to customers who have
                                scanned the QR code available on the Sampling Kit and
                                completed the registration process. Consumers are required
                                to fill in the following details for registration:
                            </p>
                        </li>
                        <li dir="ltr">
                            <p dir="ltr">
                                Mandatory Fields:
                            </p>
                        </li>
                    </ol>
                </ol>
            </ol>
            <div style={{textAlign:"left"}}>
                
            <ul>
                <li dir="ltr">
                    <p dir="ltr">
                        Customer Name
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Mobile Number (Verified by OTP)
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Email ID
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        State (Dropdown selection)
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Select Outlet from the list of outlets mapped against each state.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Age of Pet (in Months)
                    </p>
                </li>
            </ul>
            <p dir="ltr">
                Non-Mandatory Fields:
            </p>
            <ul>
                <li dir="ltr">
                    <p dir="ltr">
                        Name of the Pet
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Breed of Pet
                    </p>
                </li>
            </ul>
            </div>
            <ol start={4}>
                <li dir="ltr">
                    <p dir="ltr">
                        Upon submitting the details, the consumer's registration process
                        will be completed.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Upon successful registration, a unique code will be generated on the
                        microsite.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        The customer will share the unique code along with their registered
                        mobile number with the retailer. The retailer will give Sampling Kit
                        to the consumer.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        The retailer will then visit the retailer redemption portal and
                        enter the following:
                    </p>
                </li>
            </ol>
            <div style={{textAlign:"left"}}>

            <ul>
                <li dir="ltr">
                    <p dir="ltr">
                        Mobile Number (Verified via OTP)
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Unique Code received from the customer
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Upon verification of the code, the retailer will receive
                        confirmation and must enter their UPI-linked mobile number to
                        receive the cashback.
                    </p>
                </li>
            </ul>
            </div>
            <ol start={8}>
                <li dir="ltr">
                    <p dir="ltr">
                        After verification, the retailer will receive their cashback,
                        including Rs. 100 directly transferred to their UPI-linked mobile
                        number, within 24-48 hours
                    </p>
                </li>
            </ol>
            <ol start={7}>
                <li dir="ltr">
                    <p dir="ltr">
                        Redeeming Discount for Coupon:
                    </p>
                </li>
                <ol>
                    <li dir="ltr">
                        <p dir="ltr">
                            Consumers will receive 2 coupon codes in the sampling kit.
                            During their next purchase, if the consumer presents these codes
                            to the retailer, they will benefit from a Rs. 250 discount on
                            each coupon code. The terms and conditions of coupon code to be
                            applied by retailer while giving discounts to consumer.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            The retailer can claim the allowed discount by visiting the
                            retailer redemption portal and will receive Rs. 250 for each
                            coupon directly transferred to their UPI-linked mobile number.
                        </p>
                    </li>
                </ol>
                <li dir="ltr">
                    <p dir="ltr">
                        Participants understand that Prizes are limited to this contest
                        only. This contest shall not have any bearing on existing trade
                        relations.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Mere participation in the Program or submitting the Pictures does
                        not entitle the participant to the prize(s) unless all other terms
                        and conditions are fully met. Failure to comply with any of the
                        terms and conditions hereof or any fraudulent conduct shall
                        disentitle the participant.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        By participating in the Program, each participant agrees to hold
                        harmless NESTLÉ India and its affiliates, directors, officers,
                        employees and agents, as well as all others associated with the
                        development and execution of this Program, from and against any and
                        all liability with respect to or in any way arising from this
                        Program, the awarding and use, misuse or possession of the prize,
                        including liability for personal injury, except where prohibited or
                        limited by law.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        No queries, claims, dispute, complaints or grievances shall be
                        entertained by Nestlé India Ltd. after 30 days from the date of the
                        closure of the Program. For program T&amp;Cs, please visit{" "}
                        <a href="https://starterretailerkit.woohoo.in/">
                            https://starterretailerkit.woohoo.in/
                        </a>
                        {" "}In case of any query, please contact on 080-69806393
                    </p>
                </li>
            </ol>
            <p dir="ltr">
                NESTLÉ India reserves the right to change the terms and conditions of this
                Program and to alter or terminate the Program at any time without prior
                notice. An updated version of the terms and conditions will be communicated
                to participant via SMS by Third-party agency Pine Labs Pvt. Ltd. for this
                Program
            </p>
            <ol start={12}>
                <li dir="ltr">
                    <p dir="ltr">
                        By entering into the Program and accepting the prize, the
                        Participants and prize winner(s), respectively, waive all the
                        copyrights, rights of publicity and any related rights and
                        specifically consents to Nestlé India's right to audiography,
                        videography, picture, tape or portray him/her as a participant/
                        prize winner and to exhibit this material in any and all media now
                        existing or hereinafter created, including (without limitation)
                        television, film, radio and print media without any compensation
                        whatsoever for such advertising, and publicity for perpetuity,
                        except where prohibited by law. The Participants and/or prize
                        winner(s) also consents to Nestlé India's unconditional right to use
                        his/her name, voice or picture or the contents of his/her Program
                        entry/ post for perpetuity.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        The winners must, at the request of Nestlé India Ltd., participate
                        in all promotional activity (such as publicity and photography)
                        surrounding the winning of the prize, free of charge, and they
                        consent to Nestlé India Ltd. using their name and image in
                        promotional material.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        The participant agrees to be bound by the terms, including in
                        particular those relating to the collection, retention, use and
                        sharing of personal data. Further, the participant confirms that
                        he/she has gone through Nestlé's Privacy Policy -
                        <a href="">
                            www.Nestlé.in/info/privacypolicy
                        </a>
                        and that he/she has no objection in collection or processing of
                        their data according to the manner carved out in the privacy policy.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Your personal data as collected by Nestlé India's
                        associates/partners or affiliates shall pass your personal data to
                        Nestlé India. Your personal data shall be administered and treated
                        Nestlé India's privacy and data collection guidelines. Such data
                        collected and retained may be used by Nestlé India and or its
                        related associates/partners/ affiliates for marketing, internal
                        study, and data analysis purposes.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Nestlé India encourages you to read the privacy notice of any such
                        company before sharing personal data. If you do not want your
                        personal data to be collected by or shared with a company other than
                        Nestlé India, you can always choose not to participate in such
                        activity. If you do opt-in to communications from such a company,
                        remember that you always have the right to opt-out and you would
                        need to contact that company directly to do so.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        If you have any questions or comments regarding this Privacy Notice
                        or Nestlé's personal data collection practices, please contact us by
                        sending an email Generic. INDataPrivacy01@IN.Nestlé.com
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        NESTLÉ India shall not be liable for any failure to comply with its
                        obligations mentioned herein, caused by weather conditions, fire,
                        flood, hurricane, strike, war, riots, political unrest, civil
                        commotion, terrorist attacks, or any other event amounting to Force
                        Majeure and beyond their control.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        NESTLÉ India shall not be liable for any failure to comply with its
                        obligations mentioned herein, caused by Participant’s address being
                        in an unreachable location. The Participant will have to provide an
                        alternate address where Courier services are available to claim the
                        prize.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        If a Participant is dissatisfied with the Program or the Program
                        rules and/or any content or any requirements of the Program form,
                        the materials available related thereto, or with these terms and
                        conditions, his/her sole and exclusive remedy is to not participate
                        in the Program.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        The Program is not available (void) where prohibited by any law. All
                        relevant national and local laws apply. All disputes to be settled
                        within the jurisdiction of the Courts in Delhi only.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        By participating in the Program, each participant specifically
                        accepts as follows (a) that he/she has fully read and understood all
                        the terms and conditions and be unconditionally bound by them b).
                        that he/she satisfies the eligibility requirements under these rules
                        c) that he/she unconditionally accepts the winner so selected d)
                        that he/she shall honour all decisions of NESTLÉ India with regard
                        to the Program and its interpretation and not challenge them (e) to
                        comply with all Central, State, Local Laws and Regulations.
                    </p>
                </li>
            </ol>



        </Popup>
    );
};

export default TermsPopup;
